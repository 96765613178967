.dh-container {
  padding: 29px 29px 16px 28px;
}
.logo {
  width: 143px;
  height: 54px;
}
@media (max-width: 700px) {
  .logo {
    width: 87px;
    height: 33px;
  }

  .head-left {
    width: 200px;
    min-width: 200px;
  }
}

@media (min-width: 701px) {
  .head-left {
    width: 325px;
    min-width: 325px;
  }
}

.head-left {
  position: relative;
}

.logout-btn {
  background: #fa5d72;
  border-radius: 50px;
  padding: 10px;
  font-weight: 600;
  font-size: 12px;

  text-transform: uppercase;

  color: #ffffff;
}
