#mirrorprofiles-add-accounts {
    background: transparent;
    color: #3153C7;
    border: 2px solid #3153C7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #mirrorprofiles-add-accounts img {
    margin-right: 10px; 
    margin-left: 20px;
  }
  
  div#mirrorprofiles-buttons {
    padding: 0 5px;
    position: relative;
    z-index: 1;
  }
  
  div#mirrorprofiles-buttons button {
    border-radius: 50px;
    padding: 15px 20px;
    font-family: Montserrat-Medium;
    font-size: 0.9em;
    width: 85%;
    margin: 0 auto 12px auto;
    cursor: pointer;
  }
  