/* Conversations */
div#conversations-container {
    min-width: 390px;
    width: 390px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding: 0 20px;
    box-sizing: border-box;
    height: calc(100vh - 225px);
    position: relative;
}
div#conversations-header {
    padding: 0 10px;
    background: #ffffff;
}
div#conversations-header h1 {
    font-family: Montserrat-SemiBold;
    font-size: 1.5em;
    font-weight: normal;
    padding-left: 10px;
    margin: 10px 0;
    color: #000000;
    display: none;
}

/* Search Conversations */
div#search-conversations-container {
    display: flex;
    flex-direction: row;
    margin: 25px 0 0 0;
    border-radius: 5px;
    overflow: hidden;
    background: transparent;
    border: 1px solid #CCC9C9;
}
input#search-conversations-input {
    flex-grow: 1;
    padding: 8px;
    outline: none;
    border: none;
    background: transparent;
    color: #000000;
    font-family: Montserrat;
    font-size: 0.9em;
}
input#search-conversations-input::placeholder {
    color: #CCC9C9;
}
button#search-conversations, .search-btn {
    font-size: 1em;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    color: #CCC9C9;
    padding: 8px;
    transition: color 0.1s;
}
button#search-conversations:hover, .search-btn {
    color: #000000;
}

/* Conversation Unread Filter */
div#conversations-unread-filter-container {
    display: flex;
    /* overflow: hidden; */
    background: #FFFFFF;
    border-bottom: 1px solid #C9C9C9;

    /* box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2); */
    /* border-radius: 29.5px; */
    /* padding: 10px; */
    /* margin: 20px 0; */
    /* background: linear-gradient(to right, #FDFBFB, #EBEDEE 70%) */
}
div#conversations-unread-filter-container button {
    flex-grow: 1;
    border: none;
    background: transparent;
    color: #000000;
    padding: 0 0 10px 0;
    cursor: pointer;
    margin: 0;
    outline: none;
    font-size: 16px;
    font-family: Montserrat;
    position: relative;
}
div#conversations-unread-filter-container button.selected {
    font-family: 'Montserrat';
    font-weight: 900;
    text-align: center;
    /* border-bottom: 3px solid #1E93FD; */
    /* border-style: solid;

    border-color: #1E93FD; */
    background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}
div#conversations-unread-filter-container button.selected .selectedBar{
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: -1.5px;
    border-radius: 3px;
    background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
}

.opacity-container {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -6.76%, #FFFFFF 38.7%);
    position: absolute;
    bottom: 0;
    height: 105px;
    width: calc(100% - 20px);
    opacity: .9;
}
.infinite-scroll-component__outerdiv {
    height: 100%;
    margin-top: 25px;
}

div#congratz_center {
    vertical-align: center;
}