input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background-repeat:  no-repeat;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"%3E%3Cpath d="M3.16887 14.4189C2.82326 14.4189 2.52729 14.2959 2.28097 14.05C2.03506 13.8037 1.91211 13.5077 1.91211 13.1621V4.36483C1.91211 4.01922 2.03506 3.72347 2.28097 3.47756C2.52729 3.23124 2.82326 3.10808 3.16887 3.10808H3.79724V2.46399C3.79724 2.28595 3.85736 2.13933 3.97759 2.02412C4.09824 1.90892 4.24758 1.85132 4.42562 1.85132C4.60366 1.85132 4.75301 1.91143 4.87366 2.03166C4.99389 2.15231 5.054 2.30166 5.054 2.4797V3.10808H10.081V2.46399C10.081 2.28595 10.1414 2.13933 10.262 2.02412C10.3822 1.90892 10.5314 1.85132 10.7094 1.85132C10.8874 1.85132 11.0366 1.91143 11.1568 2.03166C11.2775 2.15231 11.3378 2.30166 11.3378 2.4797V3.10808H11.9662C12.3118 3.10808 12.6077 3.23124 12.8541 3.47756C13.1 3.72347 13.2229 4.01922 13.2229 4.36483V13.1621C13.2229 13.5077 13.1 13.8037 12.8541 14.05C12.6077 14.2959 12.3118 14.4189 11.9662 14.4189H3.16887ZM3.16887 13.1621H11.9662V6.87835H3.16887V13.1621ZM3.16887 5.62159H11.9662V4.36483H3.16887V5.62159Z" fill="%233A7DFF"%3E%3C/path%3E%3C/svg%3E');

}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 5%;
}
input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 8%;
}
input::-webkit-datetime-edit {
    position: relative;
    left: 8%;
}