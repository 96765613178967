.collapsed-right-wrapper {
  max-width: 160px;
  width: 160px;
  padding-left: 20px;
  flex-basis: 100%;
}
.right-info-container {
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  border-radius: 10px 0px 0px 10px;
  padding: 15px;
  align-items: center;
  cursor: pointer;
}
.expanded-wrapper {
  max-width: 380px;
  width: 380px;
  flex-basis: 100%;
  padding-left: 20px;
  padding-bottom: 30px;
}
.full-profile-container {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  max-width: 310px;
  width: 310px;
  padding: 22px 0 0 0;
  position: relative;
  max-height: 80vh;
  overflow: auto;
}
.user-icon {
  height: 144px;
  width: 144px;
  border-radius: 50%;
  display: block;
  margin: 30px auto auto auto;
}
.profile-basic-info {
  padding: 18px;
  /* border-bottom: 1px solid #c9c9c9; */
  text-align: center;
}

/* expanded rh */
.userinfo-interlocutor {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-top: 16px;
}
.userinfo-prospector {
  padding: 2px 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  background: #3153c7;
  border-radius: 2px;
  display: table;
  margin: 2px auto;
  color: #ffffff;
}
.userinfo-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.userinfo-email,
.userinfo-phone {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 16px;
}
.userinfo-email span,
.userinfo-phone span {
  margin: 0 12px;
  text-overflow: ellipsis;
  width: 120px;
  text-align: left;
  overflow: scroll;
  white-space: nowrap;
}
.copy-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.userinfo-linkedin-cta {
  display: flex;
  width: 169px;
  margin: 28px auto 5px auto; 
  align-items: center;
  justify-content: center;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 8px 18px;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  border-radius: 50px;
}

.blur-text-placeholder {
  color: transparent;
  background: linear-gradient(to right, #ccc 20%, #eee 50%, #ccc 80%);
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  width: 169px; 
  height: 21px;
  cursor: pointer;
}

.blur-text-placeholder::after {
  content: 'romain@stepward.com';
  display: block;
  filter: blur(5px);
  height: 21px;
  color: #000000;
}
