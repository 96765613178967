.endtrial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; /* Add a fixed height to reduce the size of the middle box */
  width: 35%;
  max-width: 400px;
  margin-top: 150px;
}

.endtrial-container .login-details {
  background: linear-gradient(to bottom, #1E93FD, #3154C8);
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  width: 100%;
  max-width: 860px;
  text-align: center;
  padding: 41px 10px 59px 10px;
  height: 100%; /* Set the height to 100% to fill the available space within the endtrial-container */
}


.endtrial-background {
  position: fixed;
  bottom: 10px; /* Adjust the value to prevent cropping */
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.endtrial-background img {
  width: 25%; /* Increase the value to make the image bigger */
  height: auto;
  object-fit: contain; /* Scale the image to fit within the container */
}

.endtrial-container .logo {
  width: 175px;
  height: 66px;
}
.endtrial-container .login-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* or 34px */

  text-align: center;

  color: #ffffff;
}


.endtrial-container .login-details .width-wrap {
  width: 300px;
  margin: auto;
}

.endtrial-container .endtrial-btn {
  background: #ffffff;
  border-radius: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  color: #1E93FD;

  outline: none;
  border: none;
  width: 280px;
  padding: 14px;
  margin-top: 20px;
  cursor: pointer;
}

.endtrial-container .endtrial-btn-cta {
  background: transparent;
  border-radius: 50px;
  border: 1px solid #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;

  outline: none;
  width: 280px;
  padding: 14px;
  margin-top: 20px;
  cursor: pointer;
}


.endtrial-container .login-details .label-wrap {
  margin-top: 12px;
}
.endtrial-container .login-details .label-wrap label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #ffffff;
}
.endtrial-container .login-details input {
  border: 1px solid #1e93fd;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
  padding-left: 10px;
}
.change-password-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: #1e93fd;
  float: right;
  cursor: pointer;
}
.forgot-subhead-endtrial {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}

.retour-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  /* gradient */

  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  /* background: #FFFFFF; */
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  outline: none;
  border-radius: 50px;
  border: 1px solid #1e93fd;

  width: 280px;
  padding: 14px;
  margin-top: 20px;
  cursor: pointer;
}
.validation-string {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #757575;
}

/* Add this media query to adjust the layout for smaller screens */
/* Add this media query to adjust the layout for smaller screens */
@media (max-width: 768px) {
  .endtrial-container {
    width: 80%; /* Increase the width of the middle box to take up more space on smaller screens */
  }

  .endtrial-container .login-details {
    padding: 20px; /* Reduce the padding of the middle box to make it smaller on smaller screens */
  }

  .endtrial-container .endtrial-btn,
  .endtrial-container .endtrial-btn-cta {
    width: 100%; /* Set the width of the buttons to 100% to take up the full width of the middle box on smaller screens */
  }

  .endtrial-background img {
    width: 40%; /* Reduce the width of the background image to make it smaller on smaller screens */
  }
}

/* Add this media query to adjust the layout for larger screens */
@media (min-width: 769px) {
  .endtrial-container {
    width: 30%; /* Set the width of the middle box to a fixed value for larger screens */
  }

  .endtrial-container .login-details {
    padding: 41px 10px 59px 10px; /* Set the padding of the middle box to the original value for larger screens */
  }

  .endtrial-container .endtrial-btn,
  .endtrial-container .endtrial-btn-cta {
    width: 280px; /* Set the width of the buttons to a fixed value for larger screens */
  }

  .endtrial-background img {
    width: 25%; /* Set the width of the background image to the original value for larger screens */
  }
}

@media (max-height: 700px) {
  .endtrial-background {
    display: none;
  }
}
