/* Canned responses */
div#canned-responses-layer {
  position: fixed;
  top: 0;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
div#canned-responses-container {
  background: #ffffff;
  width: 70%;
  height: 70%;
  max-height: 70%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}
div#canned-responses-header {
  margin: 0;
  padding: 20px;
  background: transparent;
  border-bottom: 1px solid #c9c9c9;
  display: flex;
  flex-direction: row;
}
span#canned-responses-label {
  margin: 0;
  flex-grow: 1;
  text-align: left;
  color: #000000;
  font-family: Montserrat-SemiBold;
  font-size: 1.2em;
  font-weight: normal;
  margin-left: 10px;
}
button#close-canned-responses {
  border: none;
  font-size: 1.2em;
  margin-left: auto;
  cursor: pointer;
  color: #000000;
  background: none;
}
div#canned-responses-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 20px;
  height: 70%;
}
div#canned-responses-column {
  width: 214px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 75px);
}
div#canned-response-container {
  flex-grow: 3;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
}
ul#canned-responses {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  height: 100%;
  /* max-height: 200px; */
  flex: 1;
}
ul#canned-responses li.canned-response-item {
  font-family: Montserrat;
  font-size: 1em;
  color: #000000;
  margin: 10px 0;
  padding: 10px 20px;
  cursor: pointer;
  /* background: #d9d9d9; */
  border-radius: 5px;
}
ul#canned-responses li.canned-response-item.selected {
  background: linear-gradient(90deg, #3153c7 0%, #1e93fd 100%);
  color: #ffffff;
  font-family: Montserrat-SemiBold;
}
div#create-canned-response-button-container {
  display: flex;
  /* flex-direction; */
  justify-content: right;
}
button#create-canned-response {
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  border-radius: 50px;
  border: none;
  margin: 0;
  padding: 10px 20px;
  font-size: 1.1em;
  font-family: Montserrat-Medium;
  color: #ffffff;
  cursor: pointer;
}
div#canned-response-preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  padding: 30px;
}
input#canned-response-name-input {
  font-size: 18px;
  font-family: Montserrat-SemiBold;
  padding: 11px 5px 11px 16px;
  background: none;
  border-radius: 10px;
  border: 1px solid #C9C9C9;
  outline: none;
  color: #2a2b2f;
  margin-bottom: 9px;
}
input#canned-response-name-input::placeholder {
  color: #c2c5cf;
}
textarea#canned-response-input {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  flex-grow: 1;
  border: 1px solid #c9c9c9;
  resize: none;
  font-family: Montserrat;
  font-size: 14px;
  outline: none;
  /* box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2); */
  border-radius: 10px;
  padding: 17px;
}
div#canned-response-variables-bar {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 12px;
}
div#canned-response-variables-bar button.add-canned-response-variable {
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family: Montserrat;
  color: #ffffff;
  padding: 6px 8px;
  margin: 0 5px;
  border-radius: 3px;
  background: #2287f3;
}
div#canned-response-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 30px;
}
div#canned-response-action-bar button {
  border: none;
  /* padding: 8px 15px; */
  cursor: pointer;
  color: #393c45;
}
div#canned-response-action-bar button:not(#add-canned-response) {
  color: #3153c7;
  background: transparent;
  /* background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  font-size: 1.6em;
}
div#canned-response-action-bar button#add-canned-response {
  color: #ffffff;
  font-family: Montserrat-Medium;
  border-radius: 50px;
  margin-left: auto;
  padding: 8px 18px;
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  font-size: 14px;
}
.create-canned-response-container {
  display: flex;
  align-items: center;
  /* margin-top: 30px; */
  cursor: pointer;
  border: 2px solid #2262ff;
  border-radius: 50px;
  padding: 8px 18px;
  justify-content: space-between;
}
.add-cr-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #2287f3;
  margin-left: 10px;
}

.canned-search input {
  background-color: transparent;
  border: 0px solid;
  height: 20px;
  width: 160px;
  color: #000000;
  margin-left: 5px;
}

.canned-search input:focus {
  outline: none;
}

.canned-search {
  border: 1px solid #C9C9C9;
  margin-top: 20px;
  border-radius: 5px;
  margin: 5px;
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.canned-search img {
  margin: 5px;
  width: 15px;
  height: 15px;
}



@media (max-width: 700px) {
  /* Canned Responses */
  div#canned-responses-container {
    width: 90%;
    height: 90%;
    max-height: 90%;
  }
  div#canned-responses-body {
    flex-direction: column;
  }
  div#canned-responses-column {
    width: 100%;
    border: none;
    border-bottom: 2px solid #e9ebf1;
    max-height: 150px;
  }
  div#canned-response-container {
    overflow: auto;
  }
  textarea#canned-response-input {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
