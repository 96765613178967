
.status-icon-wrap {
    position: absolute;
    /* margin-left: 30%; */
    top: 12px;

    /* width: 100%; */
    /* max-width: 300px; */
    z-index: 10;
    right: 0;
}
.msg-heading-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-right: 12px;
}
.msg-body-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 9px;
    margin-right: 12px;

}
.status-message-wrap{
    background: #B1DBFC;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    width: 100%;
    padding: 10px 10px 20px 17px;
    max-width: 300px;
    left: calc(100% + 15px);
    z-index: 10;
}
.triangle-left {
    position: absolute;
    left: -11px;
    top: 21px;

	width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-right: 14px solid #B1DBFC;
    border-bottom: 14px solid transparent;
}
@media (max-width: 700px) { 

    .status-message-wrap{
        top: calc(100% + 15px);
        left: 0;
    }
    .triangle-left {
        top: -16px;
        right: 7px;
        left: auto;
        transform: rotate(90deg);
    }
}