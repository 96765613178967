button#mirrorprofiles-add-accounts {
    background: transparent;
    color: #3153C7;
    border: 2px solid #3153C7;
}
div#mirrorprofiles-add-accounts-layer {
    position: fixed;
    height: 100vh;
    height: -webkit-fill-available;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    top: 0;
    left: 0;
}
div#mirrorprofiles-add-accounts-container {
    background: #ffffff;
    width: 640px;
    height: 510px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    padding: 14px;
}
div#mirrorprofiles-add-accounts-header {
    margin: 0;
    padding: 36px 20px 0 20px;
    background: transparent;
    display: flex;
    flex-direction: row;
}
span#mirrorprofiles-add-accounts-label {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    color: #000000;
    font-family: Montserrat-Bold;
    font-size: 1.5em;
    font-weight: normal;
}
button#mirrorprofiles-close-add-accounts-window {
    border: none;
    font-size: 1.2em;
    margin-left: auto;
    cursor: pointer;
    color: #000000;
    background: none;
}
div#mirrorprofiles-add-accounts-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* padding: 36px 20px 0 20px; */
}
div#mirrorprofiles-add-accounts-body p {
    text-align: center;
    color: #000000;
    font-family: Montserrat;
    font-size: 0.9em;
    width: 100%;
    margin-bottom: 40px;
}
div#mirrorprofiles-add-accounts-info {
    background: #EFEFEF;
    color: #757575;
    font-family: Montserrat;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    margin: 0 38px 0 38px;
}
div#mirrorprofiles-add-accounts-info-left {
    margin: 0 20px;
    font-size: 1.1em;
}
div#mirrorprofiles-add-accounts-info-right {
    flex-grow: 1;
    font-size: 0.9em;
}
div#mirrorprofiles-add-accounts-buttons {
    flex-grow: 1;
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
div#mirrorprofiles-add-accounts-buttons a {
    border-radius: 50px;
    padding: 15px 20px;
    font-family: Montserrat-Medium;
    font-size: 0.9em;
    width: 100%;
    margin: 0 0 12px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
}
a#mirrorprofiles-rent-account {
    border: none;
    background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
    color: #ffffff;
}
a#mirrorprofiles-rent-account-more-infos {
    background: transparent;
    color: #3153C7;
    border: 2px solid #3153C7;
}
a#conversation-prospect-profile-redirection {
    text-decoration: none;
}

@media (max-width: 700px) {
    span#mirrorprofiles-add-accounts-label {
        font-size: 1.25em;
    }
    #mirrorprofiles-add-accounts-info-left {
        margin: 0 10px!important;
    }
    div#mirrorprofiles-add-accounts-header {
        padding-top: 20px!important;
    }
    #mirrorprofiles-add-accounts-info {
        margin: 0!important;
    }
    div#mirrorprofiles-add-accounts-info-right {
        font-size: .66em;
    }
}