.dashboard-center {
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
    border-radius: 10px 10px 0px 0px;
}
/* Conversation */
div#conversation-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    z-index: 2;
    box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
    border-radius: 10px 10px 0 0;
    position: relative;
    height: 100%;
}
div#conversation-top-bar {
    background: transparent;
    border-bottom: 1px solid #C9C9C9;
    display: flex;
    flex-direction: row;
    padding: 8px;
    max-height: 150px;
    box-sizing: border-box;
}
button#close-conversation, button#conversation-more-actions {
    background: none;
    border: none;
    outline: none;
    color: #2b2b2b;
    padding: 16px;
    font-size: 1.4em;
    cursor: pointer;
}
button#close-conversation {
    display: none;
}
button#show-participants-informations {
    display: none;
    margin-left: auto;
}
div#conversation-prospect-informations {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    padding: 5px;
}
img#conversation-prospect-profile-picture {
    width: 50px;
    border-radius: 25px;
    margin-right: 5px;
}
div#conversation-prospect-informations-left{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
div#conversation-prospect-informations-right {
    margin-left: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
span#conversation-prospect-name {
    font-family: Montserrat-Bold;
    font-size: 1em;
    color: #000000;
}
span#conversation-prospector-name {
    font-family: Montserrat;
    background: #3153C7;
    border-radius: 2px;
    padding: 1px 5px;
    font-size: 0.8em;
    display: inline-block;
    margin: 3px 0;
    color: #ffffff;
}
span#conversation-prospect-headline {
    font-family: Montserrat;
    color: #000000;
    font-size: 0.8em;
}
ul#conversation-more-actions-dropdown {
    position: absolute;
    right: 40px;
    top: 71px;
    background: #ffffff;
    list-style: none;
    margin: 0;
    padding: 8px 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    /* border: 1px solid #C9C9C9; */
    z-index: 10;
    border: 1px solid #1E93FD;
}
ul#conversation-more-actions-dropdown li.action-item {
    margin: 5px 0;
    display: flex;
}
ul#conversation-more-actions-dropdown button {
    font-family: Montserrat;
    font-size: 0.8em;
    background: none;
    border: none;
    margin: 0;
    padding: 5px;
    cursor: pointer;
}

/* Messages */
ul#conversation {
    padding: 0;
    overflow-y: auto;
    width: 100%;
    flex-grow: 1;
    margin: 0;
    scroll-behavior: smooth;
}
li.message {
    list-style: none;
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
}
li.message.prospector {
    flex-direction: row-reverse;
}
li.message.semi-transparent {
    opacity: 0.5;
}
li.message img.author-icon {
    width: 40px;
    border-radius: 20px;
    margin-top: 1em;
}
li.message div.message-header {
    font-size: 1em;
}
li.message.prospector div.message-header {
    text-align: right;
}
li.message span.author {
    display: none;
}
li.message.prospector p.message-content {
    background: #1E93FD;
    color: #ffffff;
}
li.message p.message-content {
    margin: 0;
    font-size: 0.8em;
    font-family: Montserrat;
    padding: 10px;
    border-radius: 6px;
    white-space: pre-line;
    color: #000000;
    background: #D9D9D9;
}
li.message span.time {
    font-size: 0.7em;
    color: #9e9e9e;
    font-family: Montserrat;
}
li.message div.message-left {
    width: 50px;
    min-width: 50px;
    margin: 7px;
}

/* Message Input */
div#message-input-container {
    margin: 0;
    box-sizing: border-box;
    background: #E8E8E8;
    display: flex;
    flex-direction: row;
    padding: 8px;
}
div#canned-responses-button-column {
    display: flex;
    flex-direction: column;
}
button#show-canned-responses {
    border: none;
    outline: none;
    color: #333333;
    padding: 13px;
    cursor: pointer;
    background: none;
    font-size: 1.2em;
}
div#message-input-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
textarea#message-input {
    border: none;
    outline: none;
    font-family: Montserrat;
    font-size: 0.8em;
    box-sizing: border-box;
    padding: 10px 20px;
    margin: 0;
    background: #ffffff;
    resize: none;
    max-height: 200px;
    border-radius: 8px;

}
textarea#message-input::placeholder {
    color: #c2c5cf;
}
div#send-message-button-column {
    display: flex;
    /* flex-direction: column-reverse; */
    align-items: center;

}
button#send-message, button#edit-save-message, button#edit-cancel-message {
    border: none;
    outline: none;
    border-radius: 10px;
    background: transparent;
    color: #3153C7;
    /* background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: red; */
    margin: 6px;
    padding: 8px;
    cursor: pointer;
    font-size: 1.4em;
}

@media (max-width: 700px) {
    /* Conversations */
    div#conversations-container {
        width: 100%;
    }
    ul#conversations {
        border: none;
    }

    div#conversation-container {
        max-height:calc(100vh - 55px)

    }
    /* Conversation */
    button#show-participants-informations {
        display: inline-block;
    }
    button#close-conversation {
        display: inline-block;
    }

    /* Canned Responses */
    div#canned-responses-container {
        width: 90%;
        height: 90%;
        max-height: 90%;
    }
    div#canned-responses-body {
        flex-direction: column;
    }
    div#canned-responses-column {
        width: 100%;
        border: none;
        border-bottom: 2px solid #e9ebf1;
        max-height: 150px;
    }
    div#canned-response-container {
        overflow: auto;
    }
    textarea#canned-response-input {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    /* Participants' Informations */
    div#participants-informations-container {
        width: 100%;
        border: none;
    }
    button#hide-participants-informations {
        display: inline-block;
    }

    /* MirrorProfiles */
    img#mirrorprofiles-logo {
        display: none;
    }
    div#mirrorprofiles-add-accounts-container {
        width: 90%;
        max-width: 90%;
        height: 90%;
        max-height: 580px
    }
    div#mirrorprofiles-add-accounts-body {
        padding: 10px;
    }
}

@media (min-width: 701px) and (max-width: 1150px) {
    div#participants-informations-container {
        flex-grow: 1;
        border: none;
    }
    button#show-participants-informations {
        display: inline-block;
    }
    button#hide-participants-informations {
        display: none;
    }
}

.ctrl-enter-send {
    background: #C7C7C7;
    border-radius: 1px;
    color: #757575;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 8px;
    text-align: center;
    padding: 2px 4px;
}

.edit-cancel-btn, .edit-save-btn {
    font-family: Montserrat;
    font-size: 15px!important;
    font-style: normal;
    font-weight: 400;
    color: #1E93FD;
    padding: 0!important;
  }

.edit-save-btn {
    /* border: 1px solid #3154C8!important; */
    /* border-radius: 12px!important; */
    font-weight: 600;
}