.message {
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
}
.message.prospector {
    flex-direction: row-reverse;
}
.message.semi-transparent {
    opacity: 0.5;
}
.message img.author-icon {
    width: 40px;
    border-radius: 20px;
    /* margin-top: 1em; */
}
.message div.message-header {
    font-size: 1em;
}
.message.prospector div.message-header {
    text-agn: right;
}
.message span.author {
    display: none;
}
.message.prospector pre.message-content {
    background: #2287F3;
    color: #ffffff;

}
.message pre.message-content {
    margin: 0;
    font-size: 0.8em;
    font-family: Montserrat;
    padding: 10px;
    border-radius: 6px;
    white-space: pre-ne;
    color: #000000;
    background: #E8E8E8;
    max-width: 326px;
    white-space: pre-wrap;
    word-wrap: break-word;

}
.message span.time {
    font-size: 0.7em;
    color: #9e9e9e;
    font-family: Montserrat;
}
.message div.message-left {
    width: 50px;
    min-width: 50px;
    margin: 7px;
}
.message-right {
    display: flex;
    align-items: center;
}
.message-date-wrapper {
    display: flex;

}
.prospector .message-date-wrapper {
    flex-direction: row-reverse;
}
.message-at {
    font-weight: 400;
    font-size: 10px;
    /* line-height: 12px; */
    color: #000000;
    opacity: 0.5;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 13px;
    margin: 0
}
.expand-option {
    position: absolute;
    top: 3px; 
    left: -20px;
    cursor: pointer;
    z-index: 11;
}
.mesage-actions {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 20px 40px 0px rgba(30, 147, 253, 0.20);
    border: 1px solid #3154C8;
    padding: 0 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-height: 100px;
    position: absolute;
    /* right: 0; */
    left: -130px;
    z-index: 1000;
    top: 0px;
}
.message-input-wrapper{
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    color: #1E93FD;
    cursor: pointer;
    padding: 13px 0 13px;
}
.message-input-wrapper:not(:last-child) {
    border-bottom: 1px solid #C9C9C9;
}
.message-input-wrapper img{
    margin-right: 5px;
}
.edit-message-wrapper {
    width: 100%;
}
.edit-message-wrapper .button-group {
    display: flex;
    justify-content: end;
    margin-bottom: 17px;
}