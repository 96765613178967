.selected_label {
    position: absolute;
    border-radius: 14px;
    border: 1px solid #1E93FD;
    padding: 4px 6px;
    cursor: pointer;
    display: flex;
}
.selected_language_expanded_list {
    padding: 15px;
    border-radius: 10px;
    background: #FFF;
    border: 1px solid #1E93FD;
    box-shadow: 0px 20px 40px 0px rgba(30, 147, 253, 0.20);
    position: absolute;
    top: 30px;
    z-index: 10;
    width: 108px;
    right: -50px ;
}
.selected_language_expanded_list>div:not(:last-child) {
    border-bottom: 2px solid#C9C9C9;
    padding-bottom: 12px;
    cursor: pointer;
    display: flex;
}
.selected_language_expanded_list>div:last-child {
    padding-top: 12px;
    cursor: pointer;
    display: flex;

}
.select_language_wrapper {
    position: absolute; 
    top: 7px; 
    right: 80px
}
@media (max-width: 700px) {
    .select_language_wrapper {
       left: 130px;
       right: 0px;
    }
    
    .selected_language_expanded_list {
        left: 0px;
    }

    .status-icon-wrap {
        left: 160px;
    }
}
 