.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* height: 100vh; */
  /* flex-grow: unset; */
  justify-content: center;
  height: 100vh;
}
.login-container .logo {
  width: 175px;
  height: 66px;
}
.login-container .login-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* or 34px */

  text-align: center;

  color: #000000;
}
.login-container .login-details {
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  width: 100%;
  max-width: 860px;
  text-align: center;
  /* height: 50vh; */
  margin-top: 60px;
  padding: 41px 10px 59px 10px;
}
.login-container .login-details .width-wrap {
  width: 300px;
  margin: auto;
}

.login-container .login-btn {
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  border-radius: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;

  outline: none;
  border: none;
  width: 280px;
  padding: 14px;
  margin-top: 48px;
  cursor: pointer;
}
.login-container .login-details .label-wrap {
  margin-top: 12px;
}
.login-container .login-details .label-wrap label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #000000;
}
.login-container .login-details input {
  border: 1px solid #1e93fd;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
  padding-left: 10px;
}
.change-password-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: #1e93fd;
  float: right;
  cursor: pointer;
}
.forgot-subhead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 18px;
  margin-bottom: 52px;
  color: #000000;
}

.retour-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  /* gradient */

  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  /* background: #FFFFFF; */
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
  outline: none;
  border-radius: 50px;
  border: 1px solid #1e93fd;

  width: 280px;
  padding: 14px;
  margin-top: 20px;
  cursor: pointer;
}
.validation-string {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #757575;
}
