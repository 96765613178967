.response-list-horizontal-wrapper {
    border-top: 1px solid #C9C9C9;
    padding: 12px 32px 12px 32px;
    overflow: auto;
    max-width: 100%;
    /* overflow-x: auto; */
    /* overflow-x: scroll; */
    /* display: block; */
    /* width: 100%; */
    white-space: nowrap;
    min-height: 75px;
    max-height: 75px;
    height: 75px;
    box-sizing: border-box!important;
}
.reponse-list-title {
    color: #000;
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: 500;
    position: fixed;
}
.response-tile {
    border: 1px solid #3153C7;
    border-radius: 30px;
    padding: 6px 10px;
    margin-right: 10px;
    cursor: pointer;
    background: -webkit-linear-gradient(#1E93FD, #3153C7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    font-size: 12px;
    font-family: "Montserrat";
}