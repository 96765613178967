.btn {
  border-radius: 50px;
  padding: 4px 6px;
  font-family: Montserrat-Medium;
  font-size: 0.6em;
  width: 100%;
  cursor: pointer;
  border: 2px solid #1e93fd;
  background: linear-gradient(to right, #1e93fd, #3154c8);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
