.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
}

input[type="checkbox"] {
  display: none;
}

.checkmark {
  width: 13px;
  height: 13px;
  border: 1px solid #1e93fd; /* Border color for unchecked state */
  border-radius: 2px;
  margin-right: 8px;
  position: relative;
}

input[type="checkbox"]:checked + .checkmark {
  background-color: #1e93fd; /* Background color for checked state */
  border-color: #1e93fd; /* Border color for checked state */
}

/* Style the checkmark itself */
.checkmark::before {
  content: "";
  position: absolute;
  display: none;
}

input[type="checkbox"]:checked + .checkmark::before {
  display: block;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
