.tag-list-wrapper {
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    display: flex;
    align-items: center;
    padding-right: 18px;
    position: relative;
}
.tags-overflow-wrap{
    padding: 18px;
    white-space: nowrap;
    overflow: auto;
    width: calc(100%);
    margin-right: 5px;
    /* width: calc( 100% - 30px) ; */
}
.tag-chip {
    color: #ffffff;
    padding: 6px 10px;
    border-radius: 3px;
    margin-right: 6px;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;

    display: inline-flex;
    align-items: center;
}

.tag-chip-new {
    color: #ffffff;
    border-radius: 3px;
    margin-right: 6px;
    padding: 6px 10px;
    
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;

    display: inline-flex;
    align-items: center;
}


.tag-list-modal {
    position: absolute;
    bottom: 50px;
    width: 80%;
    border-radius: 10px;
    background: #FFF;
    padding: 18px;
    box-shadow: 0px 20px 40px 0px rgba(30, 147, 253, 0.20);
    border: 1px solid #3154C8;
    margin: auto;
    text-align: center;
    transform: translate(-50%);
    left: 50%;
}

.tag-input-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.tag-list-chip-wrapper {
    max-height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    /* white-space: nowrap; */
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 10px;
}
.tag-input-text {
    border-radius: 5px;
    background: #E8E8E8;
    padding: 10px 0px 10px 10px;
    flex-basis: 90%;
    border: 0;
    outline: 0;
    margin-right: 5px;

    /* color: #999; */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

}
.no-tag-text {
    color: #999999;
    font-family: 'Montserrat';
    font-size: 12px;
}
.tag-list-modal .tag-chip {
    cursor: pointer;
}