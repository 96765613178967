textarea#mp-textarea-input {
    border: none;
    outline: none;
    font-family: Montserrat;
    font-size: 0.8em;
    box-sizing: border-box;
    padding: 10px 20px;
    margin: 0;
    background: #ffffff;
    resize: none;
    max-height: 300px;
    border-radius: 8px;
    width: 100%;
  border: 1px solid #1e93fd;


}
textarea#mp-textarea-input::placeholder {
    color: #c2c5cf;
}