
/* List of conversations */
ul#conversations {
    flex-grow: 1;
    padding: 5px;
    margin: 0;
    overflow-y: auto;
}
ul#conversations li.conversation-item {
    list-style: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 5px 0;
    border-radius: 10px;
    background: #ffffff;
}
ul#conversations li.conversation-item.unread {
    background: #EFEFEF;
}
ul#conversations li.conversation-item.unread div.message-snippet {
    font-family: Montserrat-Bold;
}
ul#conversations li.conversation-item.unread div.prospect-name-row {
    font-family: Montserrat-Bold;
}
ul#conversations li.conversation-item.unread span.notification-counter {
    display: inline;
}
ul#conversations li.conversation-item.selected {
    border: 2px solid #1E93FD;
}
.seperator{
    border-bottom: 1px solid #CCC9C9;
    border-radius: 0!important;
}
ul#conversations li.conversation-item div.prospect-profile-picture-column {
    min-width: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
ul#conversations li.conversation-item img.prospect-profile-picture {
    width: 50px;
    border-radius: 25px;
}
ul#conversations li.conversation-item div.conversation-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 6px 6px 6px 10px;
    overflow: hidden;
}
ul#conversations li.conversation-item div.conversation-header {
    display: flex;
    flex-direction: row;
}
ul#conversations li.conversation-item div.conversation-bottom {
    display: flex;
    flex-direction: row;
}

ul#conversations li.conversation-item div.participants-column {
    flex-grow: 1;
}
ul#conversations li.conversation-item div.time-column {
    max-width: 80px;
    font-family: Montserrat;
    font-size: 0.6em;
    text-align: right;
    color: #b0b6bf;
    margin-left: auto;
}

ul#conversations li.conversation-item div.message-snippet {
    color: #000000;
    font-family: Montserrat;
    padding-top: 5px;
    font-size: 0.8em;
    flex-grow: 1;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4em;
}
ul#conversations li.conversation-item div.notification-column {
    display: none;
    margin-left: 6px;
}
ul#conversations li.conversation-item.unread div.notification-column {
    display: block;
}
ul#conversations li.conversation-item span.notification-counter {
    background: #2262ff;
    color: #ffffff;
    padding: 2px 13px;
    border-radius: 9px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Montserrat-SemiBold;
    background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
}
ul#conversations li.conversation-item div.prospect-name-row {
    color: #222325;
    font-family: Montserrat-Bold;
    font-size: 0.9em;
}
ul#conversations li.conversation-item div.prospector-name-row {
    color: #ADADAD;
    border: 1px solid #ADADAD;
    border-radius: 2px;
    display: inline;
    padding: 1px 2px;
    border-radius: 2px;
    font-family: Montserrat;
    font-size: 0.7em;
}

.check-icon{
    display: none;
}
.selected{
    position: relative;
}
.selected .check-icon{
    color: rgba(255,255,255,10);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
}
.selected-img-wrapper{
    display: none;
}
.selected .selected-img-wrapper {
    display: block;
    background: linear-gradient(90deg, rgba(30, 147, 253, 0.7) 0%, rgb(49, 83, 199, 0.7) 100%);
    /* opacity: 0.7;  */
    height: 50px;
    width: 50px; 
    position: absolute;
    border-radius: 25px;
}