.mp-custom-input-container {
  text-align: left;
}
.mp-custom-input-container .label-wrap label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  
  color: #000000;
}
.mp-custom-input-container input {
  border: 1px solid #1e93fd;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
  padding-left: 10px;
  box-sizing: border-box;
}
