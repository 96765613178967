.mp-primary-button {
    background: #2262ff;
    color: #ffffff;
    font-family: Montserrat-Medium;
    border-radius: 50px;
    margin-left: auto;
    padding: 10px 40px;
    background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
    font-size: 1.2em;
    cursor: pointer;
}
.mp-secondary-button {
    background: #fff;
    color: #AFA9A9;
    font-family: Montserrat-Medium;
    border-radius: 50px;
    margin-left: auto;
    padding: 10px 40px;
    font-size: 1.2em;
    cursor: pointer;
    border: 2px solid #AFA9A9;
}