.filter-container-wrapper {
  position: relative;
  
}
.filter-card {
  padding-top: 16px;
  padding-bottom: 25px;
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  max-height: 90vh;
  overflow: auto;
  position: relative;
}
.filter-card-header .filter-card-header div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-card-header div {
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  border-bottom: 1px solid #c9c9c9;
  align-items: center;
  padding-bottom: 20px;
}
.filter-card-body .b1 {
  /* padding: 25px 32px 12.5px 32px; */
  /* border-bottom: 1px solid #c9c9c9; */
}
.filter-card-body .b2 {
  /* padding: 12.5px 32px 25px 32px; */
}
.filter-card-footer {
  padding-left: 26px;
  padding-right: 26px;
}
.interest-chip {
  border-radius: 5px;
  background: #01bc62;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.filter-loading-wrapper {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(255,255,255,0.6);
  z-index: 10;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}