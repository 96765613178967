.webhook-container {
  position: absolute;
  right: 18px;
  top: 56px;
  width: 80%;

  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
  border-radius: 10px;
}
.webhook-test {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;

  color: #757575;
  margin-top: 3px;
}
.webhook-input-wrap {
  display: flex;
}
.webhook-input-wrap input {
  flex: 1;
  font-family: "Montserrat";
  background: #ffffff;
  border: 1px solid #1e93fd;
  border-radius: 5px 0 0 5px;
  padding: 9px 12px;
}
.webhook-input-wrap input:focus-visible {
  outline: none;
}
.check-wrap {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #1e93fd 0%, #3153c7 100%);
  border-radius: 0px 5px 5px 0px;
  width: 38px;
  justify-content: center;
  cursor: pointer;
}

/* webhook list styles */
.webhook-list-wrap {
  padding: 28px;
}
.webhook-list-wrap .webhook-list-title {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.webhook-list-wrap .webhook-list-urls {
  overflow: auto;
  max-height: 150px;
}
.webhook-list-wrap .webhook-list-urls .webhook-url-item{
  font-size: 14px;
  padding-bottom: 13px;
  margin-top: 16px;
  border-bottom: 1px solid #C9C9C9;
  display: flex;
  justify-content: space-between;
}
.webhhook-list-footer {
  color: #2287F3;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;
}